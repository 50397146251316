/* You can add global styles to this file, and also import other style files */
label {
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 500;
    color: #222;
}

.inactive td:not(:first-child) {
    background-color: rgb(231, 228, 228) !important;
    text-decoration-line: line-through;
    font-style: italic;
}

.card {
    box-shadow: 4px 3px 5px rgba(34, 34, 34, 0.207);
}
.card-header {
    color: white;
}
.noshadow {
    box-shadow: none !important;
}


.form-control.ng-invalid {
    border-left: solid 3px #FF586B;
}
.form-control.ng-touched.ng-invalid {
    border-color: #FF586B;
}

.form-control.ng-touched.ng-valid {
    border-left: solid 3px #0CC27E;
}


.row.equal-height {
    display: flex;
    flex-wrap: wrap;
}
.row.equal-height > [class*='col-'] {
    display: flex;
    flex-direction: column;
}

.card {
    flex: 1;
}
.pointer {
    cursor: pointer;
}

.ui-table .ui-sortable-column.ui-state-highlight {
    background-color: rgb(212, 212, 212);
}

.btn.btn-primary {
    color: white !important;
}

.btn.btn-default {
    background-color: #e7e7e7 !important;
}

.p-datatable .p-datatable-tbody > tr > td {
    padding: 0.2rem 0.4rem;
    font-size: 12px;
}
.p-datatable .p-datatable-thead > tr > th {
    font-size: 12px;
    padding: 0.3rem 0.4rem 0.2rem ;
}


.ml-1 {
    margin-left: 0.125rem !important;
}
.ml-2 {
    margin-left: 0.25rem !important;
}

.mr-1 {
    margin-right: 0.125rem !important;
}
.mr-2 {
    margin-right: 0.25rem !important;
}


.row {
    margin-bottom: 0.3rem !important;
}
.form-control {
    background-color: #fff;
}
.text-right {
    text-align: right !important;
}

.form-control:focus {
    background-color: #fff;
}

.p-inputtext {
    border-radius: 0;
    padding: 0.2rem 0.75rem;
    font-size: 0.8rem;
    line-height: 1.5;
}
.p-button.p-button-icon-only {
    padding: 0;
    border-radius: 0;
    background-color: #ddd;
    border-color: #ff586b;
    color: #ff586b;
    font-size: 12px;
}
.p-datepicker table {
    font-size: 0.8rem;
}
.p-datepicker table td {
    padding: 0;
}
.p-datepicker .p-datepicker-header {
    padding: 0;
}
.p-datepicker .p-datepicker-header .p-datepicker-title {
    line-height: 1rem;
}
.p-datatable .p-datatable-footer {
    padding: 0;
}

.p-dropdown-panel {
    font-size: 0.8rem;
}